<template>
    <div>
        
        <div id="公司介绍"  class="solve head-label">
            <div style="text-align: center;" class="font_size_24">
                公司介绍
            </div>
        </div>
        <!-- 公司介绍 -->
        <div style="width: 90%;background-color: #F2F6FC;margin: 10px auto;">
            <div style="display: flex; flex-direction: column;width: 100%;">
                <p class="about_me_p">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;深圳宇宙空间文化传媒有限公司，创始于2008年，是一家定位于通过移动互联网和各类数字化平台、终端，提供音乐、游戏、视频等娱乐服务的综合提供商。凭借成熟的市场专业经验与国内最丰富的版权资源，依靠巨大的品牌知名度与影响力，通过与移动等运营商、互联网、巨量引擎等相关业者的合作，为手机及互联网用户提供一系列的数字娱乐产品及服务，发展出适合各种不同载体的娱乐商品，达到将娱乐数字化、移动化及宽频化的目标。
                    公司拥有凭借雄厚的电行业基础、丰富的市场运作经验以及诸多的知名传媒机构，倾力打造“全方位手机媒体门户”概念，与中国移动、中国联通、中国电信三大运营商均建立了稳固的合作关系，旨在共同推动中国信息化社会的进程，加速中国无线通信增值业务的发展。
                    </p>
                <p class="about_me_p">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;宇宙空间是较早进入数字音乐领域的公司，现为中国最具领先地位的数字音乐服务及销售平台。全面贯通覆盖中国31个省、市、自治区的移动增值业务平台，并为多个品牌手机制造商建立手机终端音乐服务解决方案。
                </p>
            <p class="about_me_p">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                宇宙空间是三大运营商集团总部及移动互联网基地、咪咕音乐基地、沃阅读、手机动漫、手机游戏，悦跑圈等基地的长期战略合作伙伴。一直以来，各分支机构与多省本地业务开展密切合作，融合成熟的市场渠道与产品，运用行业丰富的渠道资源及客户基础，取得了诸多显著成果。
                            </p>
            </div>
        </div>
        <!-- 主营业务 -->
        <div id="主营业务" class="head-label">
            <div style="text-align: center;" class="font_size_24">
                主营业务
            </div>
        </div>
        <div class="product_mobile">
            <div style="display: flex; flex-direction: column;width: 100%;">
                <p class="about_me_p"><b>增值业务：</b>2G/3G/4G/5G手机服务（视频彩铃，移动积分商城兑换，彩信，手机阅读、游戏、动漫，短信，彩铃振铃，语音聊天，IVR歌曲，娱乐新闻，原音铃声，企业视频彩铃，运营商渠道业务等）
                    </p>
                <p class="about_me_p"><b>非增值业务：</b>演艺类，动漫类，游戏类，媒体，互联网等
                </p>
            </div>
        </div>

         <!-- 推广规划策略 -->
         <div id="推广规划策略" class="head-label">
            <div style="text-align: center;" class="font_size_24">
                推广规划策略
            </div>
        </div>
        <div class="product_pc">
            
            <el-carousel :height="computePCHeight">
                <el-carousel-item v-for="(item, index) in manages[0].img" :key="index">
                    <img :src="item"  class="product_pc_img">
                </el-carousel-item>
            </el-carousel>
        </div>

         <!-- 合作效果 -->
         <div id="合作效果" class="head-label">
            <div style="text-align: center;" class="font_size_24">
                合作效果
            </div>
        </div>
        <div class="product_mobile">
            <el-row>
                <el-col :xs="24" :sm="6">
                    <el-carousel :height="computeHeight">
                        <el-carousel-item v-for="(item, index) in products[0].imgMobile" :key="index">
                            <img :src="item"  class="product_mobile_img">
                        </el-carousel-item>
                    </el-carousel>
                </el-col>
                <el-col :xs="24" :sm="18">
                    <div class="product_mobile_content">
                        <div class="product_mobile_title font_size_30">{{products[0].content}}</div>
                    </div>
                    <el-carousel :height="computeAdminHeight">
                        <el-carousel-item v-for="(item, index) in products[0].imgAdmin" :key="index">
                            <img :src="item"  class="product_admin_img">
                        </el-carousel-item>
                    </el-carousel>
                </el-col>
            </el-row>
        </div>

         <!-- 客服团队 -->
         <div id="客服团队" class="head-label">
            <div style="text-align: center;" class="font_size_24">
                客服团队
            </div>
        </div>
        <div class="product_pc">
            
            <el-carousel :height="computePCHeight">
                <el-carousel-item v-for="(item, index) in kftd[0].img" :key="index">
                    <img :src="item"  class="product_pc_img">
                </el-carousel-item>
            </el-carousel>
        </div>
      
       

    </div>
</template>
<script>
    import { reactive, ref } from "vue";
    export default {
        name: 'Index',
        setup() {
            let videoPlayer = ref(null);
            return { videoPlayer };
        },
        data() {
            return {
                autoplay: true, //自动播放
                initialIndex: 0, //从下标第几个开始轮播
                sliders: [],
                productSolutionsImages: [
                    
                ],
                products: [
                    {
                        imgMobile: [require('../../static/hzxg_1.png'), require('../../static/hzxg_2.png'), require('../../static/hzxg_3.png')],
                        imgAdmin: [require('../../static/b_hzxg_1.png'),require('../../static/b_hzxg_2.png')],
                        title: "合作效果",
                        content: "与100多家知名wap及web网站的网络营销平以及合作项目展示"
                    }
                ],
                manages: [
                    {
                        img: [ require('../../static/tghhcl.png')],
                        title: "推广规划策略",
                        content: ["推广规划策略", ""]
                    }
                ],
                kftd: [
                    {
                        img: [ require('../../static/kftd.png')],
                        title: "客服团队",
                        content: ["客服团队", ""]
                    }
                ],
                company: [
                  
                ],

                options: reactive({
                    width: "100%", //播放器宽度
                    //height: "500px", //播放器高度
                    color: "#409eff", //主题色
                    title: "", //视频名称
                    src: "", //视频源
                    poster: "",
                    muted: false, //静音
                    webFullScreen: false,
                    speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
                    autoPlay: true, //自动播放
                    loop: false, //循环播放
                    mirror: false, //镜像画面
                    ligthOff: false, //关灯模式
                    volume: 0.3, //默认音量大小
                    control: true, //是否显示控制
                    controlBtns: [
                        "audioTrack",
                        "quality",
                        "speedRate",
                        "volume",
                        "setting",
                        "pip",
                        "pageFullScreen",
                        "fullScreen",
                    ], //显示所有按钮,
                })
            }
        },
        methods: {
            // 监听轮播图改变
            onChange(index) {
                // 如果是视频,就等播放完再进行下一个
                if (this.sliders[index].img.indexOf("mp4") != -1 && this.options["src"] !=  this.sliders[index].img) {
                    this.options["src"] = this.sliders[index].img; //把获取来的视频赋值给sources的src
                    this.options["poster"] = this.sliders[index].poster;

                    this.videoPlayer.src = this.options["src"]; // 重置进度条,再次轮播时让视频重新播放
                    this.videoPlayer.poster = this.options["poster"];
                    this.autoplay = false;
                }
            },
            onPlayerEnded() {
                //视频播放停止时候触发的事件
                this.autoplay = true;
            },
            onPlayerPlay() {
                this.autoplay = false;
            },
        },
        mounted() {
            
        },
        computed: {
            //计算高度
            computeHeight() {
                let screenHeight = document.body.clientWidth;
                if (screenHeight > 415) {
                    return '550px';
                } else {
                    return '250px';
                }
            },
            computeAdminHeight() {
                let screenHeight = document.body.clientWidth;
                if (screenHeight > 415) {
                    return '450px';
                } else {
                    return '200px';
                }
            },
            computePCHeight() {
                let screenHeight = document.body.clientWidth;
                if (screenHeight > 415) {
                    return '500px';
                } else {
                    return '200px';
                }
            },
        },
    }
</script>

<style scoped>
    .border_parent {
        width: 90%;
        margin: auto;
    }

    .slider_img {
        height: 550px;
        width: 100%;
    }

    .solve {
        margin-top: 30px;
        margin-bottom: 10px
    }

    .border {
        border-radius: 6px;
        border: 1px solid #EBEEF5;
        height: auto;
        margin: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
    }
    .border_img_parent {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .product_mobile_content {
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .product_mobile_img {
        height: 550px;
        width: 100%;
    }
    .product_admin_img {
        height: 450px;
        width: 100%;
    }
    .product_mobile_title {
        font-weight: bold;
        text-align: center;
    }

    .product_pc_one {
        text-align: center;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .font_size_30 {
        font-size: 1.875em;
    }

    .product_pc_img {
        height: 500px;
        width: 100%
    }

    .product_pc {
        width: 90%;
        align-items: center;
        margin: 10px auto;
        background: #F2F6FC;
    }

    .cooperation_parent {
        display: flex;
        justify-content: center;
        width: 90%;
        margin: 20px auto;
        align-items: center;
        height: auto
    }

    .about_me_p {
        font-size: 1.25em;
        font-weight: normal;
        padding: 0 20px;
        margin: 10px;
    }

    .border_title {
        font-family: "PingFang SC";
        margin-top: 10px;
        font-size: 1.125em;
        font-weight: bold;
        text-align: center;
    }

    .border_content_one {
        margin-top: 20px;
        font-size: 1.125em;
        text-align: center;
    }

    .border_content_two {
        margin-top: 5px;
        font-size: 1.125em;
        margin-bottom: 25px;
        text-align: center;
    }

    .img_border {
        margin-top: 15px;
        width: 80px;
        height: 80px
    }

    .cooperation {
        margin-top: 30px;
        width: 100%;
    }

    .about_me {
        display: flex;
        margin-top: 30px;
        justify-content: center;
        width: 100%;
    }

    .product_mobile {
        width: 90%;
        align-items: center;
        margin: 10px auto;
        background: #F2F6FC;
    }

    /* 屏幕设备适配 max-device-width: 400px */
    @media screen and (max-device-width: 415px) {
        .slider_img {
            height: 250px;
            width: 100%;
        }

        .border_parent {
            display: flex;
            width: 90%;
            margin: auto;
            height: auto;
            flex-direction: column;
        }

        .border {
            border-radius: 6px;
            border: 1px solid #EBEEF5;
            float: left;
            width: 100%;
            height: auto;
            margin: 10px 0 auto;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
            /*background: aquamarine;*/
        }

        .border_img_parent {
            margin-top: 0;
        }

        .product_mobile {
            width: 90%;
            align-items: center;
            margin: 20px auto;
            height: auto;
            background: #F2F6FC;
        }

        .product_mobile_content {
            width: 100%;
            margin-top: 5px;
        }

        .product_mobile_img {
            float: left;
        }

        .product_mobile_title {
            font-weight: bold;
        }

        .product_pc_one {
            width: 100%;
            margin-top: 15px;
        }

        .font_size_30 {
            font-size: 1.25em;
        }
        .product_pc_img {
            height: 280px;
            width: 100%;
        }
        .product_pc {
            width: 90%;
            height: auto;
            margin: 20px auto;
            align-items: center;
            background: #F2F6FC;
        }

        .head-label {
            display: flex;
            margin-top: 10px;
            justify-content: center;
            align-items: center;
        }

        .border_title {
            font-family: "PingFang SC";
            margin-top: 5px;
            font-size: 1.125em;
            font-weight: bold;
        }

        .border_content_one {
            margin-top: 5px;
            font-size: 1.125em;
        }

        .border_content_two {
            margin-top: 5px;
            font-size: 1.125em;
            margin-bottom: 8px;
        }

        .img_border {
            margin-top: 8px;
            width: 80px;
            height: 80px
        }

        .about_me {
            display: flex;
            margin-top: 10px;
            width: 100%;
        }
    }

    .font_size_24 {
        font-size: 1.5em;
    }
</style>