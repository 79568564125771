import { createRouter, createWebHistory  } from 'vue-router'
import Index from '../components/Index'

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Index',
            component: Index
        },
    ]
})
